import React from 'react';
import { Text, MaxWidth, Image, Button, Icon, useTracking } from '$shared/components';
import { useFrame } from '$shared/utils';
import { Gutter } from '~/shared/components/Gutter';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import NextLink from 'next/link';
import {
    StyledFooter,
    StyledGrid,
    StyledLinkList,
    StyledLink,
    StyledFooterTitle,
    StyledLinkWrapper,
    StyledAddressWrapper,
    StyledAddressText,
    StyledBottomWrapper,
    StyledBottomLinkList,
    StyledBottomLink,
    StyledCopyrightText,
    StyledSectionHeader,
    StyledSocialIconsWrapper,
    StyledSocialIconWrapper,
    StyledSocialIconLink,
    StyledSocialIconImage,
    StyledMarketSelectorWrapper,
} from './styled';
import NewsletterInput from './components/NewsletterInput';
import { Logo } from '~/shared/components/Logo';
import { useRouter } from 'next/router';
import { theme } from '~/theme';
import { useMarketSelectorState } from '~/shared/components/MarketSelector';
import ArrowRight from '$icons/arrow-right.svg';
import { useTranslation } from '~/shared/utils/translation';

export const N60Footer = () => {
    const router = useRouter();
    const { data: frame } = useFrame();
    const { show } = useMarketSelectorState();
    const { translate } = useTranslation();
    const { trackSocialLinkClicked } = useTracking();
    const {
        contactText,
        menu,
        newsletterText,
        bottomLinks,
        copyrights,
        contactTitle,
        addressTitle,
        addressText,
        socialIcons,
    } = frame?.footer || {};

    const socialLinkClicked = (title?: string, url?: string) => {
        trackSocialLinkClicked({
            social_network: title,
            social_link: url,
        });
    };

    return (
        <StyledFooter>
            <MaxWidth>
                <Gutter>
                    <StyledGrid>
                        <div>
                            {newsletterText && (
                                <StyledFooterTitle variant="display3">
                                    {newsletterText}
                                </StyledFooterTitle>
                            )}
                            <NewsletterInput />
                            {Array.isArray(socialIcons) && socialIcons.length > 0 && (
                                <StyledSocialIconsWrapper>
                                    {socialIcons.map((socialIcon, index) => (
                                        <StyledSocialIconWrapper key={index}>
                                            {socialIcon.link?.url && (
                                                <NextLink
                                                    href={socialIcon.link?.url}
                                                    passHref
                                                    prefetch={false}
                                                    legacyBehavior
                                                >
                                                    {socialIcon.link?.title && (
                                                        <StyledSocialIconLink
                                                            target={'_blank'}
                                                            onClick={() =>
                                                                socialLinkClicked(
                                                                    socialIcon.link?.title,
                                                                    socialIcon.link?.url,
                                                                )
                                                            }
                                                        >
                                                            {socialIcon.image?.src && (
                                                                <StyledSocialIconImage>
                                                                    <Image
                                                                        src={socialIcon.image.src}
                                                                        width={30}
                                                                        height={30}
                                                                    />
                                                                </StyledSocialIconImage>
                                                            )}
                                                            {socialIcon.link.title}
                                                        </StyledSocialIconLink>
                                                    )}
                                                </NextLink>
                                            )}
                                        </StyledSocialIconWrapper>
                                    ))}
                                </StyledSocialIconsWrapper>
                            )}
                        </div>
                        <div>
                            {!!menu?.length && (
                                <StyledLinkWrapper>
                                    {menu.map((menuItem, outerIndex) => {
                                        const { children } = menuItem;
                                        const validChildren = children
                                            ? children.filter((node) => node.link.url)
                                            : [];

                                        return (
                                            <StyledLinkList key={outerIndex}>
                                                <StyledSectionHeader variant="caption">
                                                    {menuItem.link.title}
                                                </StyledSectionHeader>
                                                {validChildren.map(({ link }) => (
                                                    <Text
                                                        as="li"
                                                        variant="body"
                                                        key={`${outerIndex} ${link.url}`}
                                                    >
                                                        <NextLink
                                                            href={
                                                                router.asPath.includes(
                                                                    'preview=true',
                                                                )
                                                                    ? `${link.url}?preview=true`
                                                                    : link.url
                                                            }
                                                            passHref
                                                            prefetch={false}
                                                            legacyBehavior
                                                        >
                                                            <StyledLink href="/">
                                                                {link.text}
                                                            </StyledLink>
                                                        </NextLink>
                                                    </Text>
                                                ))}
                                            </StyledLinkList>
                                        );
                                    })}
                                </StyledLinkWrapper>
                            )}
                            <StyledLinkWrapper>
                                <StyledAddressWrapper>
                                    {contactTitle && (
                                        <StyledSectionHeader variant="caption">
                                            {contactTitle}
                                        </StyledSectionHeader>
                                    )}
                                    {contactText && <StyledAddressText html={contactText} />}
                                </StyledAddressWrapper>
                                <StyledAddressWrapper>
                                    {addressTitle && (
                                        <StyledSectionHeader variant="caption">
                                            {addressTitle}
                                        </StyledSectionHeader>
                                    )}
                                    {addressText && <StyledAddressText html={addressText} />}
                                </StyledAddressWrapper>
                                <StyledMarketSelectorWrapper>
                                    <Button
                                        variant="Transparent"
                                        disableHoverAnimation={true}
                                        icon={
                                            <Icon>
                                                <ArrowRight></ArrowRight>
                                            </Icon>
                                        }
                                        onClick={show}
                                    >
                                        {translate('Kompan.MarketSelector.ChangeButtonText')}
                                    </Button>
                                </StyledMarketSelectorWrapper>
                            </StyledLinkWrapper>
                        </div>
                    </StyledGrid>
                </Gutter>
                <Gutter>
                    <StyledBottomWrapper>
                        <Logo description="Logo - footer" color={theme.colors.red} />
                        <StyledBottomLinkList>
                            <StyledCopyrightText variant="bodySm">{copyrights}</StyledCopyrightText>
                            <div style={{ flex: 1 }} />
                            {bottomLinks?.map((link, index) => (
                                <NextLink
                                    href={
                                        router.asPath.includes('preview=true')
                                            ? `${link.url}?preview=true`
                                            : link.url
                                    }
                                    passHref
                                    prefetch={false}
                                    key={index}
                                    legacyBehavior
                                >
                                    <StyledBottomLink href="/">
                                        <Text variant="bodySm">{link.text}</Text>
                                    </StyledBottomLink>
                                </NextLink>
                            ))}
                        </StyledBottomLinkList>
                    </StyledBottomWrapper>
                </Gutter>
            </MaxWidth>
        </StyledFooter>
    );
};

const N60FooterWithErrorBoundary = withErrorBoundary(N60Footer);
N60FooterWithErrorBoundary.displayName = 'N60FooterErrorBoundary';
export default N60FooterWithErrorBoundary;
