import { GetServerSidePropsContext } from 'next';
import { useEffect, useMemo } from 'react';
import { Frame, M10HeroModule, Modules, Pages, PageElement } from '~/lib/data-contract';
import { getPageProps, pageResolver } from '~/templates/pages/utils';
import { PageProvider } from '$templates/pages';
import { Layout } from '../Layout';
import getConfig from 'next/config';
import { useHeroPosition } from '~/shared/hooks/useHeroPosition/useHeroPosition';
import Script from 'next/script';
import { productPageAndDataResolver } from '$templates/pages/utils';
import { MarketSelectorDrawer } from '~/shared/components/MarketSelector';
import { DehydratedState } from 'react-query';
import { useRouter } from 'next/router';
import { KompanGetQuotePanel, useStructuredData, useTracking } from '$shared/components';
import { isSSR } from '~/shared/utils';
import { useCookieConsent, CookieConsentSettings } from '~/shared/hooks/useCookieConsent';
import { LazyRecaptcha } from '$shared/utils/lazy-recaptcha';
import { InViewContextWrapper } from '$shared/utils/in-view';
import { productDetailPageUrlRegex } from '../P70ProductDetailsPageTemplate/utils/productDetailPageUrlRegex';
import { useRelewiseTracking } from '~/shared/hooks/useRelewiseTracking/useRelewiseTracking';
import { useFrameState } from '~/shared/utils/frame/hooks/useFrameState';
import { useAuth } from '~/features/authentication';
import { myKompanLoginRedirectPageUrlFallback } from '~/lib/constants';
import dynamic from 'next/dynamic';

const P70ProductDetailsPage = dynamic(() =>
    import('../P70ProductDetailsPageTemplate').then((mod) => mod.P70ProductDetailsPage),
);
const P80SearchResultsPage = dynamic(() =>
    import('../P80SearchResultsPage').then((mod) => mod.P80SearchResultsPage),
);
const P140NotFoundPage = dynamic(() =>
    import('../P140NotFoundPage').then((mod) => mod.P140NotFoundPage),
);
const P401UnauthorizedPage = dynamic(() =>
    import('../P401UnauthorizedPage').then((mod) => mod.P401UnauthorizedPage),
);
const P410StaticNotFoundPage = dynamic(() =>
    import('../P410StaticNotFoundPage').then((mod) => mod.P410StaticNotFoundPage),
);

const P90FavoritePageTemplate = dynamic(() =>
    import('../P90FavoritePageTemplate').then((mod) => mod.P90FavoritePageTemplate),
);
const P60ModulePage = dynamic(() => import('../P60ModulePage').then((mod) => mod.P60ModulePage));
const DynamicBlockList = dynamic(() =>
    import('~/templates/blocks').then((mod) => mod.DynamicBlockList),
);

const myKompanModulesList = [
    'myKompanLoginModule',
    'myKompanLoginRedirectModule',
    'myKompanSalesDocumentModule',
    'myKompanSalesDocumentProductModule',
    'myKompanFeaturePlannerModule',
];

const Page = ({ page }: { page: Pages }) => {
    switch (page.type) {
        case 'p60ModulePage':
            return <P60ModulePage {...page} />;
        case 'p80SearchResultsPage':
            return <P80SearchResultsPage {...page} />;
        case 'p140NotFoundPage':
            return <P140NotFoundPage {...page} />;
        case 'p70ProductDetailsPageTemplate':
            return <P70ProductDetailsPage />;
        case 'p90FavoritePageTemplate':
            return <P90FavoritePageTemplate {...page} />;
        case 'p401UnauthorizedPage':
            return <P401UnauthorizedPage {...page} />;
        case 'p410StaticNotFoundPage':
            return <P410StaticNotFoundPage {...page} />;
    }

    return <DynamicBlockList elements={(page?.pageElements as Modules[]) || []} />;
};

export type DynamicPageProps = {
    page: Pages;
    statusCode?: number;
    frame?: Frame;
    isMyKompan?: boolean;
    isLocalhost?: boolean;
    hasMyKompanModule?: boolean;
    pageBaseUrl?: string;
    pageUrl?: string;
    aiInstrumentationKey?: string;
    googleRecaptchaKey?: string;
    dehydratedState?: DehydratedState;
};

export const DynamicPage = (props: DynamicPageProps) => {
    const router = useRouter();
    const { frameStateData, setFrameStateData } = useFrameState();
    const { trackPageView } = useTracking();
    const { setOrganizationSchema } = useStructuredData();
    const { setHeroIsFirstModule } = useHeroPosition();
    const { page, frame, isMyKompan } = props;
    const { setConsent, setInitialMarketingParams } = useCookieConsent();
    const { trackContentView } = useRelewiseTracking();
    const { isAuthenticated } = useAuth();

    if (!frameStateData && frame) {
        setFrameStateData(frame);
    }

    useEffect(() => {
        if (!isSSR) {
            window.addEventListener('CookieInformationConsentGiven', onCookieConsentChange, false);
        }

        setInitialMarketingParams();

        return () => {
            window.removeEventListener('CookieInformationConsentGiven', onCookieConsentChange);
        };
    }, []);

    useEffect(() => {
        if (page?.type === 'p20FrontPage' && frame) {
            setOrganizationSchema({
                '@type': frame?.structuredDataOrganization?.organizationType,
                name: frame?.structuredDataOrganization?.organizationName || '',
                alternateName: frame?.structuredDataOrganization?.organizationAlternateName || '',
                url: frame?.structuredDataOrganization?.organizationUrl || '',
                logo: frame?.structuredDataOrganization?.organizationLogo || '',
                sameAs: frame?.structuredDataOrganization?.organizationSameAsItems || [],
            });
        }
    }, [page?.type, frame]);

    const onCookieConsentChange = () => {
        const consentOptions: CookieConsentSettings = {
            functional:
                window?.CookieInformation?.getConsentGivenFor('cookie_cat_functional') == true,
            marketing:
                window?.CookieInformation?.getConsentGivenFor('cookie_cat_marketing') == true,
            required:
                window?.CookieInformation?.getConsentGivenFor('cookie_cat_preferences') == true,
            statistics:
                window?.CookieInformation?.getConsentGivenFor('cookie_cat_statistic') == true,
        };
        setConsent(consentOptions);
    };

    useEffect(() => {
        if (!page) return;
        // If is MyKompan and reaching frontpage, redirect user to either loginPage or dashboard
        if (isMyKompan && page?.type == 'p20FrontPage') {
            const dashboardPageUrl = frameStateData?.staticLinks?.myKompanDashboardPage?.url;
            const loginPageUrl = frameStateData?.staticLinks?.myKompanLoginPage?.url;
            const targetUrl = isAuthenticated && dashboardPageUrl ? dashboardPageUrl : loginPageUrl;
            router.push(targetUrl || myKompanLoginRedirectPageUrlFallback);
        }
    }, [page, frameStateData, isMyKompan, isAuthenticated]);

    useEffect(() => {
        if (page.type !== 'p70ProductDetailsPageTemplate') {
            trackPageView({
                page_type: page.type,
            });
        }
        if (page?.type == 'p60ModulePage') {
            trackContentView(page.id);
        }
    }, [router.asPath]);

    const pageElements = useMemo(() => {
        if (!page.pageElements) return [];

        return page.pageElements.map(
            (element, index) => ({ ...element, pageElementIndex: index }) as PageElement,
        );
    }, [page]);

    const hasMyKompanModule = pageElements?.some(
        (pageElement) => pageElement.type && myKompanModulesList.includes(pageElement.type),
    );

    useEffect(() => {
        let firstModuleId = undefined;
        if (
            pageElements?.[0]?.type === 'm10HeroModule' &&
            props.page.type != 'p80SearchResultsPage'
        ) {
            firstModuleId = pageElements[0].id;
            setHeroIsFirstModule(firstModuleId);
        }
    }, [pageElements]);

    const hasFloatingMenu = useMemo(() => {
        if (!pageElements || !pageElements.length) return false;

        const firstPageElement = pageElements[0];

        if (!firstPageElement) return false;

        return (
            page.type != 'p80SearchResultsPage' &&
            firstPageElement.type === 'm10HeroModule' &&
            (firstPageElement as M10HeroModule).mediaLayout !== 'contained' &&
            (firstPageElement as M10HeroModule).headlinePosition !== 'aboveMedia'
        );
    }, [pageElements]);

    const hasLoginRedirectModule = useMemo(() => {
        return !!pageElements.find((module) => module.type == 'myKompanLoginRedirectModule');
    }, [pageElements]);

    return (
        <PageProvider page={{ ...page, pageElements }} pageBaseUrl={props.pageBaseUrl}>
            <Layout
                isMyKompan={props.isMyKompan}
                isLocalhost={props.isLocalhost}
                hasMyKompanModule={hasMyKompanModule}
                hasLoginRedirectModule={hasLoginRedirectModule}
                pageBaseUrl={props.pageBaseUrl}
                pageUrl={props.pageUrl}
                hasFloatingMenu={hasFloatingMenu}
            >
                <LazyRecaptcha
                    recaptchaKey={props.googleRecaptchaKey || ''}
                    language={frame?.culture || 'en'}
                >
                    <InViewContextWrapper>
                        <Page page={{ ...page, pageElements }} />
                        <MarketSelectorDrawer />
                        <KompanGetQuotePanel />
                    </InViewContextWrapper>
                </LazyRecaptcha>
            </Layout>
            {process.env.NODE_ENV === 'production' && (
                <Script
                    id="CookieConsent"
                    src="https://policy.app.cookieinformation.com/uc.js"
                    data-culture={frame?.culture?.toUpperCase() ?? 'EN'}
                    strategy="lazyOnload"
                />
            )}
        </PageProvider>
    );
};

/**
 * Fetch the page and the frame and return as props
 *
 * CacheControl is set based on the response header from the page request
 */
export const getDynamicPageProps = async (context: GetServerSidePropsContext) => {
    const isProductPage = productDetailPageUrlRegex.test(context.resolvedUrl.split('?')[0]);
    const { serverRuntimeConfig } = getConfig();
    const { props, ...rest } = await getPageProps(
        context,
        isProductPage ? productPageAndDataResolver : pageResolver,
    );
    const pageProps = { ...props };

    if (props && serverRuntimeConfig.aiInstrumentationKey) {
        pageProps.aiInstrumentationKey = serverRuntimeConfig.aiInstrumentationKey;
    }
    if (props && serverRuntimeConfig.googleRecaptchaKey) {
        pageProps.googleRecaptchaKey = serverRuntimeConfig.googleRecaptchaKey;
    }

    return {
        props: pageProps,
        ...rest,
    };
};
