import { useMarketSelectorState } from '$shared/components/MarketSelector';
import { useCallback, useEffect, useState } from 'react';
import { MarketSelectorLanguage } from '~/lib/data-contract';
import { Sidepanel } from '~/shared/components/Sidepanel';
import { useFrame, useMarket } from '~/shared/utils';
import { Accordion, AccordionItem, useTracking } from '~/shared/components';
import {
    StyledSingleMarket,
    StyledModalWrapper,
    StyledMarketLanguageLink,
    StyledMarketLink,
} from './styled';
import { useRouter } from 'next/router';
import { MarketSelectorListItem } from '../MarketSelectorListItem';
import { useTranslation } from '~/shared/utils/translation';
import { languageCodeKey, languageDictionary } from '~/lib/twoLetterIsoCodeCountryList';
import { flattenLanguages } from '$shared/components/MarketSelector/lib/flattenLanguages';
import { useMarketCookies } from '~/shared/components/CountryRedirect';
import { usePage } from '~/templates/pages';
import NextLink from 'next/link';

export interface LanguageCollection {
    [key: string]: {
        market: string;
        languages: MarketSelectorLanguage[];
    };
}

export const MarketSelectorDrawer = () => {
    const { visible, hide } = useMarketSelectorState();
    const { data: frame } = useFrame();
    const { hrefLangs, type } = usePage();
    const [markets, setMarkets] = useState<LanguageCollection>({});
    const { events } = useRouter();
    const { translate } = useTranslation();
    const { trackCountrySelector } = useTracking();
    const { marketCookieValue, updateMarketCookieValue } = useMarketCookies();
    const { culture } = useMarket();

    useEffect(() => {
        setMarkets(flattenLanguages(frame?.marketSelector?.markets || []));
    }, [frame?.marketSelector]);

    useEffect(() => {
        events.on('routeChangeStart', hide);
        return () => events.off('routeChangeStart', hide);
    }, [events]);

    const marketAsUrl = useCallback(
        (market?: string) => {
            if (!market) return '';
            const [language, marketPath] = market?.toLowerCase()?.split('-') || null;
            if (type != 'p140NotFoundPage' && type != 'p150ErrorPage' && !!hrefLangs?.length) {
                const hreflang = hrefLangs?.find((lang) =>
                    lang.url.startsWith(`/${language}/${marketPath}`.toLowerCase()),
                );

                if (hreflang) {
                    return `${hreflang.url}`;
                }
            }
            return `/${language}/${marketPath}`;
        },
        [hrefLangs],
    );

    const getLanguageTitle = (language?: string) => {
        if (!language) return;
        return languageDictionary[language as languageCodeKey];
    };

    const onSelectCountry = (culture?: string) => {
        if (culture && marketCookieValue != 'false') {
            // Set new market if user has not declined market disclaimer
            updateMarketCookieValue(culture);
        }
        trackCountrySelector({
            country: culture,
        });
    };

    return (
        <Sidepanel
            culture={culture}
            open={visible}
            onClose={hide}
            title={translate('Kompan.MarketSelector.DrawerHeadline')}
            maxWidth={500}
        >
            <StyledModalWrapper>
                <Accordion>
                    {Object.values(markets).map((market, index) => {
                        const hasChildren = market.languages?.length > 1;

                        if (!hasChildren) {
                            const firstLanguage = market.languages?.[0]?.culture || '';
                            const href = marketAsUrl(firstLanguage);
                            return (
                                <StyledSingleMarket key={`marketSelectorDrawer_${index}`}>
                                    <NextLink href={href} passHref prefetch={false} legacyBehavior>
                                        <StyledMarketLink
                                            onClick={() => onSelectCountry(firstLanguage)}
                                        >
                                            <MarketSelectorListItem market={market.market} />
                                        </StyledMarketLink>
                                    </NextLink>
                                </StyledSingleMarket>
                            );
                        }
                        return (
                            <AccordionItem
                                header={<MarketSelectorListItem market={market.market} />}
                                key={`marketSelectorDrawer_${index}`}
                            >
                                {market.languages.map((language, marketLangIndex) => {
                                    const langaugeKey = language.culture?.split('-')[0] || '';
                                    const title = getLanguageTitle(langaugeKey.toUpperCase());
                                    return (
                                        <NextLink
                                            href={marketAsUrl(language.culture)}
                                            passHref
                                            key={`marketSelectorDrawer_${index}_${marketLangIndex}`}
                                            prefetch={false}
                                            legacyBehavior
                                        >
                                            <StyledMarketLanguageLink
                                                onClick={() => onSelectCountry(language.culture)}
                                            >
                                                {title}
                                            </StyledMarketLanguageLink>
                                        </NextLink>
                                    );
                                })}
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            </StyledModalWrapper>
        </Sidepanel>
    );
};
