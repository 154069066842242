import React from 'react';
import N20MainMenu from '~/features/navigation/components/N20MainMenu';
import N60Footer from '~/features/navigation/components/N60Footer';
import { useGalleryModal } from '~/shared/hooks/useGalleryModal/useGalleryModalState';
import { MetaFields } from '../MetaFields/MetaFields';
import { StyledBlurWrapper, StyledMain } from './styled';
import N50Breadcrumb from '$features/navigation/components/N50Breadcrumb';
import { useHeroPosition } from '~/shared/hooks/useHeroPosition/useHeroPosition';
import dynamic from 'next/dynamic';
const CountryRedirect = dynamic(() => import('~/shared/components/CountryRedirect'), {
    ssr: false,
});

type Props = {
    children?: React.ReactNode;
    isMyKompan?: boolean;
    isLocalhost?: boolean;
    hasMyKompanModule?: boolean;
    hasLoginRedirectModule: boolean;
    pageBaseUrl?: string;
    pageUrl?: string;
    hasFloatingMenu: boolean;
};

export const Layout = ({
    children,
    isMyKompan,
    hasMyKompanModule,
    hasLoginRedirectModule,
    pageBaseUrl,
    pageUrl,
    hasFloatingMenu,
}: Props): JSX.Element => {
    const { visible } = useGalleryModal();
    const { firstModuleId } = useHeroPosition();

    if (hasLoginRedirectModule) {
        return <StyledMain>{children}</StyledMain>;
    }
    if (isMyKompan || hasMyKompanModule) {
        return (
            <StyledBlurWrapper isBlurred={visible}>
                <CountryRedirect />
                <MetaFields pageBaseUrl={pageBaseUrl} pageUrl={pageUrl} />
                <N20MainMenu isFlowding={hasFloatingMenu} showLoginButton showSearch={false} />
                <StyledMain>{children}</StyledMain>
                <N60Footer />
            </StyledBlurWrapper>
        );
    }
    if (!isMyKompan && !hasMyKompanModule) {
        return (
            <StyledBlurWrapper isBlurred={visible}>
                <CountryRedirect />
                <MetaFields pageBaseUrl={pageBaseUrl} pageUrl={pageUrl} />
                <N20MainMenu isFlowding={hasFloatingMenu} showLoginButton={false} showSearch />
                {!firstModuleId && <N50Breadcrumb />}
                <StyledMain>{children}</StyledMain>
                <N60Footer />
            </StyledBlurWrapper>
        );
    }
    return <></>;
};
