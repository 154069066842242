import React, { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useFrame, useMarket } from '~/shared/utils';
import { MobileMegaMenu } from './components/MobileMegaMenu';
import { useMobileMenu } from './hooks/useMobileMenu';
import { Sidepanel, useKompanGetQuotePanelState } from '~/shared/components';
import { StyledNavigationActionButton, StyledButtonMobileWrapper } from '../N20MainMenu/styled';
import { getLocaleDirection, useTranslation } from '~/shared/utils/translation';
import LoginButton from '../N20MainMenu/components/LoginButton';

export type N35MobileMegaMenuProps = {
    /**
     * Set the id of the active node. Overwrides the activeNodeByUrl.
     * NOTE: Only checked on init
     */
    activeNodeId?: string;

    /**
     * Determin the active node by the location url.
     * NOTE: Only checked on init
     */
    activeNodeByUrl?: boolean;
};

/**
 * Mega menu for mobile. This menu renders each step as an menu page.
 */
export const N35MobileMegaMenu = ({
    activeNodeId,
    activeNodeByUrl = true,
}: N35MobileMegaMenuProps) => {
    const { close, isOpen } = useMobileMenu();
    const { data } = useFrame();
    const { mainMenu = [] } = data?.navigation || {};
    const { events } = useRouter();
    const { show } = useKompanGetQuotePanelState();
    const { data: frame, isMyKompan } = useFrame();
    const { culture } = useMarket();
    
    const { translate } = useTranslation();

    useEffect(() => {
        events.on('routeChangeError', close);
        events.on('routeChangeComplete', close);

        return () => {
            events.off('routeChangeError', close);
            events.off('routeChangeComplete', close);
        };
    }, [events, close]);

    const footerContent = useMemo(() => {
        const showGetAQuote =
            frame?.navigation?.getAQuote?.getAQuoteEnabled &&
            frame?.navigation?.getAQuote?.getAQuoteModule;
        if (!showGetAQuote) return <></>;
        return (
            <StyledButtonMobileWrapper>
                <StyledNavigationActionButton
                    variant={'Red'}
                    shade={'dark'}
                    disableHoverAnimation={true}
                    onClick={show}
                >
                    {translate('Kompan.MainMenu.GetQuoteButtonLabel')}
                </StyledNavigationActionButton>
            </StyledButtonMobileWrapper>
        );
    }, [
        frame?.navigation?.getAQuote?.getAQuoteEnabled,
        frame?.navigation?.getAQuote?.getAQuoteModule,
    ]);

    const loginButton = useMemo(() => {
        if (!isMyKompan) return <></>;
        return (
            <StyledButtonMobileWrapper>
                <LoginButton variant={'Red'} shade={'dark'} disableHoverAnimation />
            </StyledButtonMobileWrapper>
        );
    }, [isMyKompan]);

    const direction = useMemo(() => getLocaleDirection(culture || 'en') === 'rtl' ? 'left' : 'right', [culture]);
    

    return (
        <Sidepanel
            maxWidth={300}
            open={isOpen}
            onClose={close}
            direction={direction} 
            sidePadding="none"
            hideHeader
            footerContent={[footerContent, loginButton]}
        >
            <MobileMegaMenu
                menu={mainMenu}
                activeNodeByUrl={activeNodeByUrl}
                activeNodeId={activeNodeId}
            />
        </Sidepanel>
    );
};
