import { create } from 'zustand';

export type HeroPositionState = {
    firstModuleId: string | undefined;
    setHeroIsFirstModule: (isFirst: string | undefined) => void;
};

export const useHeroPosition = create<HeroPositionState>((set) => ({
    firstModuleId: undefined,
    setHeroIsFirstModule: (firstModuleId: string | undefined): void => {
        set(() => ({
            firstModuleId,
        }));
    },
}));
