import { StyledResetButton, StyledResetLink } from '$shared/components';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

const StyledItem = (theme: Theme) =>
    css({
        fontSize: theme.fontSizes.sm,
        textAlign: theme.direction === 'rtl' ? 'right' : 'left' ,
        padding: `${theme.spaces[2]} 0`,
        width: '100%',
        boxSizing: 'border-box',
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr) auto',
    });

export const StyledAnchor = styled(StyledResetLink)(({ theme }) => ({ ...StyledItem(theme) }));

export const StyledButton = styled(StyledResetButton)(({ theme }) => ({ ...StyledItem(theme) }));

export const StyledContent = styled.div<{ truncate?: boolean }>(
    {
        lineHeight: '3.5rem',
        paddingBottom: '1.2rem',
    },
    ifProp('truncate', () => ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    })),
);

export const StyledSuffix = styled.div({
    padding: '0 15',

    svg: {
        height: 15,
        width: 15,
    },
});
