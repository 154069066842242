import styled from '@emotion/styled';
import NextLink from 'next/link';
import { getFluidValue } from '~/theme/utils/getClamp';

export const StyledFavoriteButton = styled(NextLink)<{ color: string }>(({ theme, color }) => ({
    position: 'relative',
    margin: 0,
    display: 'flex',
    padding: `${getFluidValue(18)} ${theme.spaces[3]} ${theme.spaces[4]} ${theme.spaces[3]}`,
    color: color,

    'figure, svg': {
        marginTop: 0,
        color: color,
        transition: `color ${theme.animations.timingShort} ${theme.animations.easeOutCubic}`,
        fill: 'currentColor',
    },
}));

export const StyledFavoriteLength = styled.span(({ theme }) => ({
    position: 'absolute',
    top: '12px',
    right: '8px',
    fontSize: '9px',
    fontWeight: 'bold',
    lineHeight: '1rem',
    margin: 0,
    display: 'flex',
    padding: '2px',

    color: theme.colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,

    '&:after': {
        content: '""',
        width: '100%',
        backgroundColor: theme.colors.red100,
        height: '13px',
        padding: '0 7px',
        borderRadius: '10px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: -1,
    },
}));
