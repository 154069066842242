import React, { useMemo } from 'react';
import { default as MainLogo } from '$icons/logo.svg';
import { Link } from '~/shared/components';
import { StyledLogo } from './styled';
import { useFrame } from '~/shared/utils';
import { theme } from '$theme';
import { getCurrentMarketPath } from '~/templates/pages/utils';
import NextLink from 'next/link';

export type LogoProps = {
    /**
     * pass SVG Logo node example: <Logo children={<BasketIcon/>} description={'Go to basket'} />
     */
    children?: React.ReactNode;

    /**
     * Description of what the logo is and where it potientially leads to,
     * this is required for screen readers
     */
    description?: string;

    /**
     * The color of the SVG
     */
    color?: string;

    /**
     * Size variant
     */
    small?: boolean;
};

export const Logo = ({
    children = <MainLogo title="Logo" />,
    description = 'Logo - Frontpage',
    color = theme.colors.red,
    small = false,
}: LogoProps) => {
    const { data } = useFrame();
    const marketPath = getCurrentMarketPath();
    const frontPageUrl = useMemo(() => {
        return data?.staticLinks?.frontPage?.url || `/${marketPath}`;
    }, [data?.staticLinks?.frontPage?.url, marketPath]);
    return (
        <NextLink href={frontPageUrl} passHref legacyBehavior>
            <Link aria-label={description}>
                <StyledLogo fill={color} small={small}>
                    {children}
                </StyledLogo>
            </Link>
        </NextLink>
    );
};
