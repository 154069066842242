import { useCallback, useEffect } from 'react';
import { Gutter, StyledMaxWidth } from '$shared/components';
import { useRouter } from 'next/router';
import { useSearchState } from './hooks/useSearchState';
import { StyledSearchBox, StyledSearchDropdownContent } from './styled';
import { StyledDropDown } from '../N30MegaMenu/styled';
import { variants } from '../N30MegaMenu/animations';
import { useReducedMotion } from 'framer-motion';
import { SearchBar } from '~/templates/blocks/components/SearchResults/components/SearchBar';
import { useMedia } from 'react-use';
import { queries } from '~/theme';
import { useScrollLock } from '~/shared/hooks';

export const N25SearchBar = () => {
    const router = useRouter();
    const { isOpen: isSearchOpen, setIsOpen, setDropdownRef } = useSearchState();
    const { lock, unlock, isLocked } = useScrollLock();
    const shouldReduceMotion = useReducedMotion();
    const isDesktop = useMedia(queries.sm, false);
    const desktopVariant = variants(shouldReduceMotion);
    const mobileVariant = {
        active: {
            opacity: 1,
            transition: {
                duration: 0.4,
                delay: 0,
                ease: 'easeOut',
            },
        },
        inactive: {
            opacity: 0,
            transition: {
                duration: 0.25,
                ease: 'easeOut',
            },
            transitionEnd: {
                display: 'none',
            },
        },
    };

    const dynamicVariants = isDesktop ? desktopVariant : mobileVariant;

    useEffect(() => {
        // Lock for mobile screen
        if (!isDesktop) {
            if (isSearchOpen && !isLocked) {
                lock();
            } else if (!isSearchOpen && isLocked) {
                unlock();
            }
        }
    }, [isSearchOpen]);

    const onEscapeKey = useCallback(
        (e: KeyboardEvent) => {
            if (e.key == 'Escape' && isSearchOpen) {
                setIsOpen(false);
            }
        },
        [isSearchOpen],
    );

    useEffect(() => {
        window.addEventListener('keyup', onEscapeKey);

        return () => {
            window.removeEventListener('keyup', onEscapeKey);
        };
    }, [onEscapeKey]);

    useEffect(() => {
        // Event Handler for when user uses back button in browser
        const onRouteChangeComplete = () => {
            setTimeout(() => {
                setIsOpen(false);
            });
        };

        // Add Event listened for route change
        router.events.on('routeChangeComplete', onRouteChangeComplete);

        // Clean listeners on unmount
        return () => {
            router.events.off('routeChangeComplete', onRouteChangeComplete);
        };
    }, []);

    const measuredRef = useCallback((node: HTMLDivElement) => {
        setDropdownRef(node);
    }, []);

    return (
        <StyledSearchBox>
            <StyledDropDown
                variants={dynamicVariants}
                style={{ display: 'block' }}
                animate={isSearchOpen ? 'active' : 'inactive'}
                initial="inactive"
                ref={measuredRef}
            >
                <StyledMaxWidth>
                    <Gutter>
                        <StyledSearchDropdownContent>
                            <SearchBar usePredictions={true} />
                        </StyledSearchDropdownContent>
                    </Gutter>
                </StyledMaxWidth>
            </StyledDropDown>
        </StyledSearchBox>
    );
};
