import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export const StyledNavigation = styled.nav({
    position: 'relative',
});

export const StyledBackdrop = styled.div<{ height: number; active: boolean }>({
    position: 'relative',
});

export const StyledList = styled.ul(({ theme }) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    height: theme.navigationHeight.desktop,
    gap: 40,
}));

export const StyledListItem = styled.li({});

export const StyledDropDown = styled(motion.div)(({ theme: { colors } }) => ({
    position: 'absolute',
    display: 'block',
    left: 0,
    right: 0,
    top: 0,
    zIndex: -1,
    [breakpoints.reducedMotion]: {
        backgroundColor: colors.white,
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '50%',
        height: '100%',
        backgroundColor: colors.grey05,
        zIndex: -1,
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        width: '50%',
        height: '100%',
        backgroundColor: colors.white,
        zIndex: -1,
    },
}));

export const StyledDropDownContent = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
});

export const StyledMetaContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.grey05,
    paddingTop: theme.navigationHeight.desktopSmall,
    paddingRight: 55,
    marginBottom: 100,
    alignSelf: 'flex-start',
    [breakpoints.md]: {
        paddingRight: 85,
    },
    [breakpoints.lg]: {
        paddingTop: theme.navigationHeight.desktop,
        paddingRight: 170,
    },
}));

export const StyledMenuContainer = styled.div<{ columns: number }>(
    ({ theme }) => ({
        position: 'relative',
        display: 'grid',
        gap: theme.spaces[7],
        backgroundColor: theme.colors.white,
        paddingTop: theme.navigationHeight.desktopSmall,
        paddingLeft: 35,
        marginBottom: 25,

        [breakpoints.lg]: {
            paddingTop: theme.navigationHeight.desktop,
            marginBottom: 40,
            paddingLeft: 70,
        },

        '&:before': {
            position: 'absolute',
            content: '""',
            backgroundColor: theme.colors.white,
            bottom: -25,
            left: 0,
            height: 50,
            width: '100%',
            pointerEvents: 'none',

            [breakpoints.lg]: {
                bottom: -40,
            },
        },
    }),
    ifProp({ columns: 3 }, () => ({
        gridTemplateColumns: `repeat(3, 1fr)`,
    })),
    ifProp({ columns: 2 }, () => ({
        gridTemplateColumns: `repeat(2, 1fr)`,
    })),
);
